import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import queryBannerImage from "../../hooks/query-banner-image"
import useMinimalConfig from "../../hooks/use-minimal-config"
import useSiteMetadata from "../../hooks/use-site-metadata"
import replaceSlashes from "../../../utils/replace-slashes"
import { FooterType } from "../../types"
import { isDesktopScreen } from "../../../utils/measure-screen-size"

type LayoutProps = {
  footer: FooterType
  [key: string]: any
}

const FooterLogo = ({ footer, ...props }: LayoutProps) => {
  const { footerNavigation: nav, basePath } = useMinimalConfig()
  const { siteImage, siteTitle, footerCopyrightText } = useSiteMetadata()
  const logoImage = queryBannerImage(footer?.logo || siteImage)
  const [height, setHeight] = useState(18)
  const logoImageAspectRatio = logoImage ? logoImage?.node?.gatsbyImageData.width / logoImage?.node?.gatsbyImageData.height : 1

  useEffect(() => {
    setHeight(isDesktopScreen() ? 36 : 18)
  })

  return (
    <footer className={`${footer.advanced?.class || `bg-footerBackground text-footerColor`} py-6 md:py-12 px-6 sm:px-6 lg:px-0`}>
      <div className="max-w-screen-lg mx-auto md:flex md:items-center md:justify-between ">
        <div className="md:order-1">
          {logoImage && (
            <div style={{ height: `${height}px`, width: `${height * logoImageAspectRatio}px` }}>
              <GatsbyImage image={getImage(logoImage?.node)} style={{ height: `100%`, width: `100%` }} imgStyle={{ objectFit: "contain" }} alt={siteTitle} />
            </div>
          )}
        </div>

        <div className="mt-4 md:mt-0 flex md:justify-center space-x-6 md:order-2">
          {nav &&
            nav.map((item: any, itemIndex: any) => (
              <Link key={item.slug} className="text-footerColor underline text-xs md:text-base" to={replaceSlashes(`/${basePath}/${item.slug}`)}>
                {item.title}
              </Link>
            ))}
        </div>
      </div>

      {footerCopyrightText && <div className="max-w-screen-lg mx-auto pt-10 text-center md:text-left text-footerColor text-sm">{footerCopyrightText}</div>}
    </footer>
  )
}

export default FooterLogo